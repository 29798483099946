import useVacancyContext from 'hooks/context/vacancy-context';
import React, { FC, useEffect } from 'react';
import { Title3, Error } from 'components/typography/Typography';
import { Controller, useForm } from 'react-hook-form';
import Button from 'components/button/Button';
import Input from 'components/form/input/Input';
import 'twin.macro';
import { urlValidator } from 'utils/formHelpers';
import { ArrowLeft } from '@phosphor-icons/react';
import tw from 'twin.macro';
import useNavigationContext from 'hooks/context/nav-context';

type CustomUrlFormProps = {
  onSubmit: () => void;
  isUpdateView?: boolean;
  showWarning?: boolean;
  onBack?: () => void;
};
const CustomUrlForm: FC<CustomUrlFormProps> = ({
  onSubmit,
  showWarning = false,
  isUpdateView = false,
  onBack,
}) => {
  const { control, handleSubmit, errors, reset } = useForm();

  const { saveCustomUrlHandler, copyData } = useVacancyContext();
  const { currentVacancy } = useNavigationContext();

  const onCustomLinkSubmit = async ({
    'custom-url': customUrl,
  }: {
    'custom-url': string;
  }) => {
    // turn off modal once custom link is submitted
    saveCustomUrlHandler(customUrl, onSubmit);
  };

  const title = isUpdateView
    ? 'Update your custom vacancy URL'
    : 'Enter your custom vacancy URL';

  const ctaLabel = isUpdateView ? 'Update custom URL' : 'Use this custom URL';

  // update form value when vacancyData changes
  useEffect(() => {
    reset({
      'custom-url': copyData?.vac_url ?? '',
    });
  }, [copyData]);

  return (
    <form tw="w-auto sm:w-[35rem]" onSubmit={handleSubmit(onCustomLinkSubmit)}>
      <div tw="flex flex-col space-y-5">
        <Controller
          id="custom-url"
          name="custom-url"
          control={control}
          defaultValue={copyData?.vac_url ?? ''}
          rules={{
            required: {
              value: true,
              message: isUpdateView
                ? 'This field is required'
                : 'This field is required. Click on close if you want to cancel',
            },
            validate: {
              invalidUrl: (url) => {
                return (
                  urlValidator(url) ||
                  'Please enter a valid URL (e.g. https://yourcompany.com/jobs/...)'
                );
              },
              noReverting: (url) => {
                const isAlreadyExternal = currentVacancy?.has_custom_url;
                if (url.includes('sympl.be/vacatures')) {
                  return isAlreadyExternal
                    ? 'You cannot revert to the default URL'
                    : 'You cannot use the default URL';
                }
              },
            },
          }}
          render={({ value, onChange }) => (
            <div>
              <Title3>{title}</Title3>
              <Input
                type="text"
                autoGrow={true}
                value={value}
                placeholder={'e.g. https://yourcompany.com/jobs/...'}
                onChange={(e) => onChange(e.target.value)}
              />
              {errors['custom-url'] && (
                <Error>{errors['custom-url'].message}</Error>
              )}
            </div>
          )}
        />

        <div tw="ml-auto w-full flex flex-col gap-2">
          <Button
            type="submit"
            stretch
            variant={
              errors['custom-url']
                ? 'default'
                : isUpdateView
                ? 'indigo'
                : 'danger'
            }
            disabled={errors['custom-url']}
          >
            {ctaLabel}
          </Button>
          {onBack && (
            <Button
              type="button"
              stretch
              variant="default"
              customStyle={tw`flex items-center justify-center space-x-2`}
              onClick={onBack}
            >
              <ArrowLeft weight="bold" />
              <span>Go back</span>
            </Button>
          )}
        </div>
      </div>
      {showWarning && (
        <p tw="text-yellow-900 text-sm w-full bg-yellow-100 border-l-4 border-l-yellow-500 p-4 mt-4">
          <b>Warning:</b>
          <br />
          By using your own vacancy url, you will not be able to use the one
          provided by sympl for this campaign.
        </p>
      )}
    </form>
  );
};

export default CustomUrlForm;
