import React, { useRef, useState } from 'react';
import tw, { styled } from 'twin.macro';
import ColorPicker from 'components/form/ColorPicker';
import useDefocusHandler from 'hooks/defocus';
import useVacancyContext from 'hooks/context/vacancy-context';
import { TextAUnderline, PaintBrushBroad } from '@phosphor-icons/react';

const VacancyEditorColor: React.FC = () => {
  const { copyData, setCopyData } = useVacancyContext();

  const [showBgColors, setShowBgColors] = useState(false);
  const [showTxtColors, setShowTxtColors] = useState(false);

  const ColorRef = useRef<HTMLDivElement>(null);

  useDefocusHandler(ColorRef, () => {
    setShowBgColors(false);
    setShowTxtColors(false);
  });

  const changePickerToBg = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    // Only toggle if the click is not on the color picker
    if ((e.target as HTMLElement).closest('#bgColor') === null) {
      setShowBgColors(!showBgColors);
      setShowTxtColors(false);
    }
  };

  const changePickerToTxt = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    // Only toggle if the click is not on the color picker
    if ((e.target as HTMLElement).closest('#txtColor') === null) {
      setShowTxtColors(!showTxtColors);
      setShowBgColors(false);
    }
  };

  return (
    <div ref={ColorRef}>
      <FloatingButton onClick={changePickerToBg}>
        <PaintBrushBroad weight="bold" tw="text-xl" />
        {showBgColors && (
          <ColorPicker
            id="bgColor"
            onChange={(e) =>
              setCopyData({
                ...copyData!,
                primary_color: e,
              })
            }
            value={copyData?.primary_color ?? '#6c6a6a'}
            variant="barebones"
          />
        )}
      </FloatingButton>
      <FloatingButton onClick={changePickerToTxt}>
        <TextAUnderline weight="bold" tw="text-xl" />
        {showTxtColors && (
          <ColorPicker
            id="txtColor"
            onChange={(e) =>
              setCopyData({
                ...copyData!,
                secondary_color: e,
              })
            }
            value={copyData?.secondary_color ?? '#faf4f4'}
            variant="barebones"
          />
        )}
      </FloatingButton>
    </div>
  );
};

export const FloatingButton = styled.div<{
  loading?: boolean;
  disabled?: boolean;
}>`
  ${tw`h-10 w-10 rounded-full flex justify-center items-center cursor-pointer mb-2 bg-white ring-1 ring-gray-100 text-gray-600 shadow-lg`}

  ${({ loading, disabled }) =>
    loading || disabled
      ? [
          tw`shadow-none text-gray-400`,
          loading && tw`cursor-progress`,
          disabled && tw`cursor-not-allowed pointer-events-none`,
        ]
      : tw`hover:bg-gray-50 active:(bg-gray-100 shadow-md)`}
`;

export default VacancyEditorColor;
