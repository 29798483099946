import React, { useRef, useState } from 'react';
import tw, { css, styled, theme, TwStyle } from 'twin.macro';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';

export interface EditableInputProps {
  id: string;
  maxLength?: number;
  defaultValue?: string;
  placeholder?: string;
  style?: Object;
  styleWrapper?: TwStyle[];
  disabled?: boolean;
  errorMessage?: string;
  editorRows?: number;
  /** Removes all html mark up, when enabled*/
  sanitizeContent?: boolean;
  onChange?: (value: string) => void;
  onError?: (err?: string) => void;
  /** Set key prop to force rerender when child changes (temp bugfix) */
  key: any;
  validationRegex?: RegExp;
}

const EditableInput: React.FC<EditableInputProps> = ({
  id,
  style,
  styleWrapper,
  defaultValue,
  maxLength,
  placeholder,
  disabled = false,
  errorMessage,
  editorRows = 1,
  sanitizeContent = true,
  onChange,
  onError,
  validationRegex,
}) => {
  const txtRef = useRef<string>(defaultValue ?? '');
  const editableRef = useRef<HTMLInputElement>(null);
  const DEFAULT_EDITOR_HEIGHT = 20;
  const [editorHeight, setEditorHeight] = useState(
    `${DEFAULT_EDITOR_HEIGHT}px`
  );

  const [html, setHtml] = useState(defaultValue ?? '');
  const [isValidLength, setIsValidLength] = useState(true);

  const cleanHTML = (html: string) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };

  const blurHandler = () => {
    if (disabled) return;
    const { current: value } = txtRef;
    isValidLength ? onChange?.(cleanHTML(value)) : onError?.();
    setEditorHeight(`${DEFAULT_EDITOR_HEIGHT}px`);
  };

  const pasteHandler = (event: React.ClipboardEvent<HTMLDivElement>) => {
    if (disabled) return;
    event.preventDefault();

    // Extract and sanitize the pasted text
    const pastedText = event.clipboardData.getData('text').replace(/:~:.*/, '');
    const sanitizedText = sanitizeContent
      ? cleanHTML(sanitizeHtml(pastedText))
      : pastedText;

    // Validate the pasted text length
    const isValidLengthCheck = maxLength
      ? sanitizedText.length <= maxLength
      : true;

    if (
      !isValidLengthCheck ||
      (validationRegex && !validationRegex?.test(sanitizedText))
    ) {
      onError?.();
      return;
    }

    // Update state with valid sanitized content
    setIsValidLength(isValidLengthCheck);
    txtRef.current = sanitizedText;
    setHtml(sanitizedText);
  };

  const changeHandler = (event: ContentEditableEvent) => {
    if (disabled) return;

    const isClearingInput = event.target.value.length === 0;

    if (maxLength)
      setIsValidLength(
        event.target.value.replace('<br>', '').length <= maxLength
      );

    // Update the input value
    if (isClearingInput || isValidLength) txtRef.current = event.target.value;
    else onError?.();

    // Update the html result
    setHtml(
      sanitizeContent ? sanitizeHtml(event.target.value) : event.target.value
    );
  };

  const clickHandler = () => {
    editableRef?.current?.focus?.();
    setEditorHeight(`${editorRows * DEFAULT_EDITOR_HEIGHT}px`);
  };

  return (
    <Wrapper
      isDisabled={disabled}
      isValid={isValidLength}
      inputId={id}
      css={styleWrapper}
    >
      <ContentEditable
        id={id}
        innerRef={editableRef as any}
        tagName="p"
        html={html}
        fake-placeholder={placeholder}
        onBlur={blurHandler}
        onChange={changeHandler}
        onClick={clickHandler}
        onPaste={pasteHandler}
        disabled={disabled}
        style={
          style
            ? { ...style, minHeight: editorHeight }
            : {
                color: theme`colors.gray.800`,
                minHeight: editorHeight,
              }
        }
      />
      {errorMessage && !isValidLength && (
        <div tw="absolute right-0 mt-1">
          <p tw="text-red-400 text-xs">{errorMessage}</p>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  isDisabled: boolean;
  isValid?: boolean;
  inputId: string;
}>`
  ${tw`cursor-text relative`}

  ${({ isDisabled }) =>
    !isDisabled &&
    tw`
      cursor-pointer
      hover:(ring-2 ring-indigo-400 bg-indigo-50 rounded-sm)
      focus:(ring-2 ring-indigo-400 bg-indigo-50 rounded-sm)
    `}

  ${({ isValid }) =>
    !isValid &&
    tw`
    ring-2 ring-red-400 bg-red-50 rounded-sm
    hover:(ring-2 ring-red-400 bg-red-50 rounded-sm)
    focus:(ring-2 ring-red-400 bg-red-50 rounded-sm)
  `}

  ${({ inputId }) => css`
    // https://stackoverflow.com/a/34846535
    #${inputId}:empty:not(:focus)::before {
      content: attr(fake-placeholder);
    }
  `}
`;

export default EditableInput;
