import React, { useContext, useState } from 'react';
import 'twin.macro';

import Title from 'components/title/Title';
import SurveyUploadForm from '../SurveyUploadForm';
import Button from 'components/button/Button';
import { SurveyContext } from 'context/SurveyContext';
import { X } from '@phosphor-icons/react';

export function LogoSideBar() {
  const [uploadMode, setUploadMode] = useState<'logo'>();
  const { surveyData, logo, setLogo } = useContext(SurveyContext);
  return (
    <div tw="w-full p-5 xl:w-[350px]">
      <div tw="mx-auto">
        <div tw="mb-8 flex flex-col overflow-x-hidden">
          <div tw="mb-3 border-b-2 border-gray-100">
            <Title>Select Image</Title>
          </div>
          {!uploadMode ? (
            <div tw="flex flex-col justify-center items-center w-full gap-2">
              <div tw="flex w-full flex-wrap gap-1 p-2">
                <div tw="flex-1">
                  <Button
                    variant={logo ? 'success' : 'primary'}
                    onClick={() => setUploadMode('logo')}
                    stretch
                  >
                    {logo ? 'Change logo' : 'Choose a logo'}
                  </Button>
                </div>
                {logo && (
                  <Button variant="inverted" onClick={() => setLogo(null)}>
                    <X weight="bold" tw="text-gray-600" />
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <>
              <SurveyUploadForm
                defaultValue={
                  surveyData?.vacSurvey?.logo_id
                    ? [surveyData?.vacSurvey.logo_id]
                    : undefined
                }
                mode={'logo'}
                onUpload={() => setUploadMode(undefined)}
                setLogo={setLogo}
              />
              <button
                tw="text-sm text-gray-800 font-medium p-2 pb-4 bg-gray-200"
                onClick={() => setUploadMode(undefined)}
              >
                Close
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
